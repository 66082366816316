define("discourse/plugins/diabeteswiser-high-engagement-device-posts/discourse/initializers/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "test",
    initialize() {
      console.log("device-comment-plugin is loaded!");
    }
  };
});