define("discourse/plugins/diabeteswiser-high-engagement-device-posts/discourse/controllers/admin-plugins-high-engagement-device-posts", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _tracking, _controller, _object, _service, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsHighEngagementDevicePostsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "isIndex", [_tracking.tracked], function () {
      return true;
    }))();
    #isIndex = (() => (dt7948.i(this, "isIndex"), void 0))(); // @tracked tentacleVisible = false;
    static #_3 = (() => dt7948.g(this.prototype, "isAccepted", [_tracking.tracked], function () {
      return false;
    }))();
    #isAccepted = (() => (dt7948.i(this, "isAccepted"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "isDismissed", [_tracking.tracked], function () {
      return false;
    }))();
    #isDismissed = (() => (dt7948.i(this, "isDismissed"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "dataReady", [_tracking.tracked], function () {
      return false;
    }))();
    #dataReady = (() => (dt7948.i(this, "dataReady"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "data", [_tracking.tracked], function () {
      return [];
    }))();
    #data = (() => (dt7948.i(this, "data"), void 0))();
    constructor() {
      super(...arguments);
      // String comparison using handlebars is crazy complicated so we doing it this way
      if (this.isIndex) this.loadHighEngagementPosts();
      if (this.isAccepted) this.loadHighEngagementPosts("accepted");
      if (this.isDismissed) this.loadHighEngagementPosts("dismissed");
    }
    async loadHighEngagementPosts() {
      let type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      let queryId = this.siteSettings.high_engagement_device_posts_query_id;
      if (!type) {
        console.log("load high engagement posts");
      } else if (type === "accepted") {
        console.log("load accepted posts");
        queryId = this.siteSettings.accepted_high_engagement_device_posts_query_id;
      } else if (type === "dismissed") {
        console.log("load dismissed posts");
        queryId = this.siteSettings.dismissed_high_engagement_device_posts_query_id;
      }
      const response = await (0, _ajax.ajax)(`/admin/plugins/explorer/queries/${queryId}/run`, {
        method: "POST"
      });
      const data = response; // assuming the response has the required JSON data in a `result` key
      // Prepare the rows and columns dynamically
      const columns = data.columns;
      const rows = data.rows.map(row => {
        const rowData = {};
        row.forEach((value, index) => {
          rowData[columns[index]] = value;
        });
        return rowData;
      });
      this.data = {
        columns,
        rows
      };
      this.dataReady = true;
    }
    disableActionButtons(post_id) {
      const actionButtons = $(`.engagement-post-actions.actions-for-${post_id}`);
      actionButtons.find("button").prop("disabled", true);
    }

    // @action
    // toggleTentacle() {
    //   this.tentacleVisible = !this.tentacleVisible;
    // }

    toggleView(view) {
      if (view === "index") {
        this.isIndex = true;
        this.isAccepted = false;
        this.isDismissed = false;
        this.loadHighEngagementPosts();
      }
      if (view === "accepted") {
        this.isIndex = false;
        this.isAccepted = true;
        this.isDismissed = false;
        this.loadHighEngagementPosts("accepted");
      }
      if (view === "dismissed") {
        this.isIndex = false;
        this.isAccepted = false;
        this.isDismissed = true;
        this.loadHighEngagementPosts("dismissed");
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "toggleView", [_object.action]))();
    testPost(post_id) {
      console.log("Test");
      (0, _ajax.ajax)(`/admin/plugins/high-engagement-device-posts/test_post/${post_id}`, {
        type: "GET"
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_8 = (() => dt7948.n(this.prototype, "testPost", [_object.action]))();
    acceptPost(post_id) {
      let userConfirmation = confirm("You are about to create a new post on the draft DiabetesWise website.\n\nBe sure you have asked the original poster for permission to use their post.\n\nIf you choose to continue you will be redirected to the draft.diabeteswise.org");
      if (userConfirmation) {
        console.log("Use");
        this.disableActionButtons(post_id);
        (0, _ajax.ajax)(`/admin/plugins/high-engagement-device-posts/accept_post/${post_id}`, {
          type: "POST"
        }).then(response => {
          if (response.status === "success") {
            window.open(response.redirect, "_blank");
            setTimeout(() => {
              this.toggleView("accepted"); // go to accepted and reload posts
            }, 1500);
          } else {
            console.log("unable to redirect", response);
          }
        }).catch(_ajaxError.popupAjaxError);
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "acceptPost", [_object.action]))();
    dismissPost(post_id) {
      let view = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      let userConfirmation = confirm("You are about to dismiss the post and remove it from the draft DiabetesWise website.\n\nTo use the post again you will have to resurrect it from the dismissed posts.\n\nAre you sure you want to continue?");
      if (userConfirmation) {
        console.log("Dismiss");
        this.disableActionButtons(post_id);
        (0, _ajax.ajax)(`/admin/plugins/high-engagement-device-posts/dismiss_post/${post_id}`, {
          type: "POST"
        }).then(response => {
          if (response.status === "success") {
            setTimeout(() => {
              if (view === "accepted_view") {
                this.loadHighEngagementPosts("accepted");
              } else this.loadHighEngagementPosts();
            }, 1500);
          }
        }).catch(_ajaxError.popupAjaxError);
      }
    }
    static #_10 = (() => dt7948.n(this.prototype, "dismissPost", [_object.action]))();
    resurrectPost(post_id) {
      console.log("Resurrect");
      this.disableActionButtons(post_id);
      (0, _ajax.ajax)(`/admin/plugins/high-engagement-device-posts/resurrect_post/${post_id}`, {
        type: "POST"
      }).then(response => {
        if (response.status === "success") {
          setTimeout(() => {
            this.loadHighEngagementPosts("dismissed"); // stay on dismissed and reload posts
          }, 1500);
        }
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_11 = (() => dt7948.n(this.prototype, "resurrectPost", [_object.action]))();
  }
  _exports.default = AdminPluginsHighEngagementDevicePostsController;
});